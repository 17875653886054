import React from 'react'
import { useAuth } from '../../providers/AuthProvider'
import { Tabs, TabList, Tab, TabPanels, TabPanel, Text, Flex, Spacer, HStack, Button, Box } from '@chakra-ui/react'
import { Outlet, NavLink } from 'react-router-dom'

const Main = () => {
  const auth = useAuth()

  return (
    <>
      <p>Yo</p>
    </>
  )
}

export default Main
