import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { AuthProvider } from './providers/AuthProvider'
import AuthRequired from './components/AuthRequired'
import { ChakraProvider } from '@chakra-ui/react'
import theme from './theme'
import HelthCheck from './pages/HelthCheck/HelthCheck'
import Login from './pages/Login/Login'
import Main from './pages/Main/Main'
import PageNotFound from './pages/PageNotFound/PageNotFound'
import Logs from './pages/Main/Logs/Logs'
import Courses from './pages/Main/Courses/Courses'
import User from './pages/Main/User/User'

const rootElement = document.getElementById('root')
if (rootElement == null) {
  throw new Error('Failed to find the root element')
}

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/health-check" element={<HelthCheck />} />
            <Route path="/" element={<Main />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ChakraProvider>
  </React.StrictMode>,
)
